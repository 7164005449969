import throttle from '@utils/throttle';
import { Snackbar } from '@xo-union/tk-component-alerts';
import FooterNav from '@xo-union/tk-component-footer-nav/remote';
import '@xo-union/tk-ui-essentials';
import { LayoutRoot } from '@xo-union/ui-accessibility';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import { setIsOpen } from '../../redux/alert';
import { setWindowWidth } from '../../redux/viewport';
import { analyticsProps, metaData } from '../../utils/membershipHelpers';
import { HeaderNavWithInjectedLinks } from './HeaderNavWithInjectedLinks';
import HelmetImagePreloadRenderer from './HelmetImagePreloadRenderer';
import Styles from './styles.scss';

export const Layout = (props) => {
	const {
		windowWidth,
		route: { routes },
		closeAlert,
		alertState,
		loggedIn,
		isMobileApp,
	} = props;
	const alert = useRef(null);

	useEffect(() => {
		const { content, link, isOpen } = alertState;
		if (isOpen && alert.current) {
			if (link) {
				alert.current.showAndDismiss({
					data: {
						content,
						href: link.href,
						linkText: link.linkText,
						type: 'WITH_LINK',
					},
					dismissTimeout: 6000,
				});
				return;
			}
			alert.current.showAndDismiss({
				data: {
					content,
					type: 'NO_LINK',
				},
				dismissTimeout: 6000,
			});
			closeAlert();
		}
	}, [alertState.isOpen]);

	const onWindowResize = (event) => {
		windowWidth(event.target.innerWidth);
	};

	useEffect(() => {
		const throttleResize = throttle(onWindowResize, 250);

		if (window) {
			window.addEventListener('resize', throttleResize);
		}
		return () => {
			if (window) {
				window.removeEventListener('resize', throttleResize);
			}
		};
	}, []);

	return (
		<HelmetImagePreloadRenderer>
			<div>
				<div className={Styles.alert}>
					<Snackbar ref={alert}>
						{(data) => {
							if (typeof data === 'undefined' || data === null) {
								return null;
							}
							if (data.type === 'WITH_LINK') {
								return (
									<Fragment>
										{data.content}{' '}
										<a href={data.href} target="_blank" rel="noreferrer">
											{data.linkText}
										</a>
									</Fragment>
								);
							}
							return <Fragment>{data.content}</Fragment>;
						}}
					</Snackbar>
				</div>
				{!isMobileApp && (
					<HeaderNavWithInjectedLinks
						analyticsProps={analyticsProps}
						metadata={metaData}
						loggedIn={loggedIn}
					/>
				)}
				<LayoutRoot>
					<div className={Styles.contentContainer}>
						<div className="render-route-container" suppressHydrationWarning>
							{renderRoutes(routes)}
						</div>
					</div>
				</LayoutRoot>
				{isMobileApp ? (
					<div className={Styles.footerReplacement} />
				) : (
					<FooterNav analyticsProps={analyticsProps} ssi />
				)}
			</div>
		</HelmetImagePreloadRenderer>
	);
};

Layout.propTypes = {
	closeAlert: PropTypes.func.isRequired,
	windowWidth: PropTypes.func.isRequired,
	pageType: PropTypes.string.isRequired,
	route: PropTypes.shape({
		routes: PropTypes.array.isRequired,
	}).isRequired,
	alertState: PropTypes.shape({
		link: PropTypes.shape({
			href: PropTypes.string.isRequired,
			linkText: PropTypes.string.isRequired,
		}),
		isOpen: PropTypes.bool.isRequired,
		content: PropTypes.oneOfType([
			PropTypes.arrayOf(PropTypes.node),
			PropTypes.node,
			PropTypes.oneOf([null]),
		]).isRequired,
	}).isRequired,
	loggedIn: PropTypes.bool.isRequired,
	isMobileApp: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
	alertState: state.alert,
	loggedIn: !!state.membership.member,
	isMobileApp: state.viewport.isMobileApp,
});

const mapDispatchToProps = (dispatch) => ({
	closeAlert: () => dispatch(setIsOpen(false)),
	windowWidth: (width) => dispatch(setWindowWidth(width)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
