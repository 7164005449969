import { State } from '@typings/redux';
import {
	Impression,
	ImpressionType,
	SegmentImpression,
} from 'types/vendorImpressions';
import NavigationHelper from '../../../helpers/navigation';
import { buildFiltersParams } from '../../pages/VendorsSearch/utils';

const getMarketCode = (state: Redux.State): string | null => {
	switch (state.page.pageType) {
		case 'best of weddings': {
			return state.bow.location.market || null;
		}

		case 'category': {
			return state.location.marketCode || null;
		}

		case 'city': {
			return state.settings.marketCode || null;
		}

		case 'storefront': {
			const vendorMarketCode = state.vendor.vendorRaw?.marketCode;
			const referredMarketCode =
				NavigationHelper.sanitizedVendorReferredMarketCode(vendorMarketCode);
			return referredMarketCode || vendorMarketCode;
		}

		default:
			return null;
	}
};

const getStateCode = (state: Redux.State): string | null => {
	if (state.page.pageType === 'state') {
		return state.states.code;
	}

	return null;
};

export const getAppliedFilters = (state: Redux.State): string | null => {
	if (state.page.pageType !== 'city') {
		return null;
	}

	const filtersString = buildFiltersParams(
		state.filters.categoryFilters,
	).replace('?', '');
	return filtersString.length === 0 ? null : filtersString;
};

export const getLegacyUserId = (reduxState: Redux.State): number | null => {
	const { member } = reduxState.membership;
	if (!member?.legacy_user_id) {
		return null;
	}

	return Number(member.legacy_user_id);
};

export const isOnDirectoryPage = (state: Redux.State): boolean =>
	state.page?.pageType === 'city';

export const getDirectorySortScoreId = <T extends Vendor.PopulatedBase>(
	state: Redux.State,
	vendor: T,
): number | null => {
	if (isOnDirectoryPage(state)) {
		return vendor.personalizedScoreId || null;
	}

	return null;
};

const getListingDetailsImpressionType = (
	state: Redux.State,
): VendorImpressions.ListingDetailsImpressionType => {
	if (isOnDirectoryPage(state)) {
		return 'IVOL';
	}

	return null;
};

export const getListingDetails = (
	state: Redux.State,
	cardUiLocation?: string,
): string => {
	const onDirectoryPage = isOnDirectoryPage(state);
	const listingDetailsImpressionType = getListingDetailsImpressionType(state);
	const page = onDirectoryPage ? state.search.pagination.page : null;
	const listingDetails: VendorImpressions.ListingDetails = {
		category_code: state.category.code,
		market_code: getMarketCode(state),
		page,
		impression_type: listingDetailsImpressionType,
		state_code: getStateCode(state),
		...(onDirectoryPage && {}),
	};

	return JSON.stringify(listingDetails);
};

export const hasLeadId = (
	impressions: VendorImpressions.Impression[],
): boolean => {
	return impressions.some((impression) => impression.list.match(/Lead Gen:/));
};

export const getLeadId = (state: Redux.State): string | null =>
	state.rfq.rfqFormData.conversationId || null;

export const hasParentVendorId = (
	impressions: VendorImpressions.Impression[],
): boolean => {
	return impressions.some((impression) =>
		impression.list.match(/Lead Gen:|Storefront -/),
	);
};

export const getParentVendorId = (state: Redux.State): string | null =>
	state.rfq.storefrontId || state.vendor.vendorRaw?.id || null;

export const getFirstImageId = (impression: Impression) => {
	const { ai_photo_id } = impression;
	if (ai_photo_id) {
		return ai_photo_id;
	}

	return impression.storefront_card_id || null;
};

const formatSegmentVendorImpression = (
	impression: Impression,
	state: State,
) => {
	return {
		storefront_id: impression.id,
		parent_lead_id: hasLeadId([impression]) ? getLeadId(state) : null,
		parent_lead_vendor_id: hasParentVendorId([impression])
			? getParentVendorId(state)
			: null,
		product_tier: impression.variant,
		list_position: impression.position,
		sort_score_id: impression.directory_sort_score_id,
		first_image: getFirstImageId(impression),
		storefront_completion_status:
			impression.storefront_completion_status || null,
	};
};

export const getBucketedImpressions = (
	impressions: Impression[],
	reduxState: State,
) => {
	return impressions.reduce<Record<ImpressionType, SegmentImpression[]>>(
		(acc, currentValue) => {
			const type = currentValue.list;
			if (acc[type]) {
				acc[type].push(formatSegmentVendorImpression(currentValue, reduxState));
			} else {
				acc[type] = [formatSegmentVendorImpression(currentValue, reduxState)];
			}

			return acc;
		},
		{} as Record<ImpressionType, SegmentImpression[]>,
	);
};

export const getAppliedFiltersForBucketedImpressions = (state: State) => {
	if (state.page.pageType !== 'city') return null;

	const { appliedFilters } = state.filters;

	return Object.keys(appliedFilters).reduce<Record<string, string[]>>(
		(acc, curr) => {
			const currentFilter = appliedFilters[curr];
			if (!currentFilter.length) return acc;
			acc[curr] = currentFilter.map((filter) => filter.value);
			return acc;
		},
		{},
	);
};
