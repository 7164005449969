import MembershipService from '@xo-union/sdk-membership';
import { conversationsMiddleware } from '@xo-union/store-conversations-redux';
import { identifyMemberMiddleware } from '@xo-union/tk-analytics-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import api from './middleware/api';

import { membershipApiKey } from '../settings';
import reducers from './redux';

const membershipService = new MembershipService({
	clientOptions: {
		env: process.env.NODE_ENV === 'production' ? 'production' : 'qa',
		apiKey: membershipApiKey,
		includeWeddingProperties: true,
	},
});

export const setSessionStorage = () => (next) => (action) => {
	if (action.type === 'middleware/SET_SESSION_STORAGE') {
		if (window?.sessionStorage) {
			window.sessionStorage.setItem(action.payload.key, action.payload.value);
			window.dispatchEvent(
				new StorageEvent(`sessionStorage-${action.payload.key}`, {
					key: action.payload.key,
					newValue: action.payload.value,
				}),
			);
		}
	}
	return next(action);
};

export const enrichHoneyBadgerError = () => (next) => (action) => {
	if (window && window.honeybadgerJS && action.type === 'ENRICH_HB_ERROR') {
		// correct action & hb is ready
		if (action.payload.jsError) {
			// seperate out error that was thrown (if provided)
			window.honeybadgerJS.notify(action.payload.jsError, action.payload); // send to hb
		} else {
			window.honeybadgerJS.notify(action.payload); // send to hb
		}
	}
	return next(action);
};
/* eslint-disable-next-line no-new-func */
const isNode = new Function(
	'try {return this===global;}catch(e){return false;}',
);

const loggerMiddleware =
	isNode() ||
	process.env.NODE_ENV === 'production' ||
	process.env.NODE_ENV === 'test'
		? []
		: [createLogger({ collapsed: true, diff: true })];

const conversationMiddleware = isNode() ? [] : [conversationsMiddleware()];

/* eslint-disable no-mixed-operators, no-underscore-dangle */
const composeEnhancers =
	(typeof window !== 'undefined' &&
		window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
	compose;

export default function (initialState = {}) {
	return createStore(
		reducers,
		initialState,
		composeEnhancers(
			applyMiddleware(
				// ordering matters
				identifyMemberMiddleware(),
				...conversationMiddleware,
				thunk.withExtraArgument({ membershipService }),
				api,
				enrichHoneyBadgerError,
				setSessionStorage,
				...loggerMiddleware,
			),
		),
	);
}
