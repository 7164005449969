import { ReactNode } from 'react';

export const setIsOpen = (status: boolean): Alert.SetIsOpen => ({
	type: 'alert/SET_IS_OPEN',
	status,
});

export const setContent = (
	content: ReactNode,
	link?: Alert.Link,
): Alert.SetContent => ({
	type: 'alert/SET_CONTENT',
	content,
	link,
});

export const setContentAndStatus = (
	content: ReactNode,
	status: boolean,
	link?: Alert.Link,
): Alert.SetContentAndStatus => ({
	type: 'alert/SET_CONTENT_AND_STATUS',
	content,
	link,
	status,
});
