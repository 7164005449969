import honeybadger from '@honeybadger-io/js';
import { experiments } from '@settings';
import { values } from '@utils/objectUtils';

type ValidAssignments = Exclude<
	Experiments.AnyAssignment,
	'original' | 'control' | null
>;
const assignments: Record<ValidAssignments, Experiments.Name> = {
	'show-match-details': 'vrmMatchDetails',
	variant1: 'ivol',
};

const isExperiment = (gatekeeper: string): boolean => {
	if (experiments[gatekeeper as keyof typeof experiments]) {
		return true;
	}
	const camel = snakeToCamel(gatekeeper);
	return !!experiments[camel as keyof typeof experiments];
};

const isAssignment = (
	gatekeeper: string,
): gatekeeper is Required<ValidAssignments> => {
	return Object.keys(assignments).includes(gatekeeper);
};

const getAssignment = (key: string) => {
	// Passed in the experiment name
	if (isExperiment(key)) {
		return {
			[key]: true,
		};
	}

	// Passed in the assignment name
	if (isAssignment(key) && assignments[key]) {
		return {
			[assignments[key]]: key,
		};
	}
	return {};
};

const deserialize = (gatekeeper?: string): Record<string, boolean | string> => {
	if (!gatekeeper) {
		return {};
	}

	try {
		const splitByComma = gatekeeper.split(',');
		const splitByColon = splitByComma.map((item) => {
			const split = item.split(':');
			return {
				[split[0]]: split[1],
			};
		});
		const isOldSyntax = splitByColon.every((item) =>
			values(item).every((value) => typeof value === 'undefined'),
		); // ?

		// Old Syntax
		if (isOldSyntax) {
			return getAssignment(splitByComma[0]);
		}

		return splitByColon.reduce((acc, curr) => {
			return {
				...acc,
				...curr,
			};
		}, {});
	} catch (err) {
		honeybadger.setContext({
			gatekeeper,
		});
		honeybadger.notify('Bad gatekeeper string provided', err);
		return {};
	}
};

const snakeToCamel = (str: string) =>
	str
		.toLowerCase()
		.replace(/([-_][a-z])/g, (group) =>
			group.toUpperCase().replace('-', '').replace('_', ''),
		);

export { deserialize };
