import {
	galleryAtom,
	isFetchingAtom,
	ratingAtom,
	reviewAiSummaryAtom,
	reviewStarBucketsAtom,
	ssrReviewsAtom,
	totalCountAtom,
} from '@components/reviews/atoms';
import { galleryReviewsAtom } from '@components/reviews/components/reviews-photos-lightbox/atoms';
import { StorefrontReviews } from '@typings/reviews';
import { largestRemainderMethod } from '@utils/largestRemainderMethod';
import { atom } from 'jotai';
import { createStore } from 'jotai/vanilla';
import type {
	ReviewAiSummaryResponseData,
	ReviewsResponseData,
	ReviewsSummary,
} from 'types/api/reviews';
import { roundReviewRating } from '../decorators/vendor/helpers.js';
import { FeatureFlags, featureFlagsAtom } from '../feature-flags';
import {
	getDetailedPricingDataToHydrate,
	getDetailedPricingInitialValues,
} from './storefront/detailed-pricing';
import {
	getPricingDataToHydrate,
	getPricingInitialValues,
} from './storefront/pricing';

export const initialState: StorefrontReviews = {
	collection: [],
	isFetching: true,
	totalCount: 0,
	rating: 0,
	buckets: {
		stars1: 0,
		stars2: 0,
		stars3: 0,
		stars4: 0,
		stars5: 0,
	},
	gallery: [],
};

interface InitialJotaiState {
	reviews: StorefrontReviews;
	reviewAiSummary: ReviewAiSummaryResponseData;
	pricing: ReturnType<typeof getPricingDataToHydrate>;
	featureFlags: FeatureFlags;
	detailedPricing: ReturnType<typeof getDetailedPricingDataToHydrate>;
}

export const reviewsAtom = atom<StorefrontReviews>(initialState);

export const setReviews = (
	store: ReturnType<typeof createStore>,
	reviewsResponse: ReviewsResponseData,
) => {
	const { data, totalCount, gallery, reviewsSummary } = reviewsResponse;
	const reviews = {
		...initialState,
		collection: data,
		totalCount,
		rating: reviewsSummary ? roundReviewRating(reviewsSummary.rating) : 0,
		buckets:
			reviewsSummary && reviewsSummary?.count > 0
				? calculateDistribution(reviewsSummary)
				: initialState.buckets,
		gallery,
	};
	store.set(reviewsAtom, reviews);
};

function calculateBucket(bucket: number, count: number) {
	return (bucket / count) * 100;
}

function calculateDistribution({
	distribution,
	count,
}: ReviewsSummary): StorefrontReviews['buckets'] {
	const buckets = largestRemainderMethod(
		[
			calculateBucket(distribution.stars5, count),
			calculateBucket(distribution.stars4, count),
			calculateBucket(distribution.stars3, count),
			calculateBucket(distribution.stars2, count),
			calculateBucket(distribution.stars1, count),
		],
		100,
	);
	return {
		stars1: buckets[4] / 100,
		stars2: buckets[3] / 100,
		stars3: buckets[2] / 100,
		stars4: buckets[1] / 100,
		stars5: buckets[0] / 100,
	};
}

const getReviewsInitialValues = (reviews: StorefrontReviews) => {
	if (!reviews) return [];
	const { buckets, collection, gallery, isFetching, rating, totalCount } =
		reviews;
	const initialGalleryReviews = collection.filter(
		(review) => review.gallery.length > 0,
	);
	return [
		[reviewStarBucketsAtom, buckets],
		[ssrReviewsAtom, collection],
		[galleryAtom, gallery],
		[isFetchingAtom, isFetching],
		[ratingAtom, rating],
		[totalCountAtom, totalCount],
		[galleryReviewsAtom, initialGalleryReviews],
	];
};

const getFeatureFlagsInitialValues = (featureFlags: FeatureFlags) => {
	if (!featureFlags) return [];

	return [[featureFlagsAtom, featureFlags]];
};

const getReviewAiSummaryInitialValues = (
	reviewAiSummary: ReviewAiSummaryResponseData,
) => {
	return [[reviewAiSummaryAtom, reviewAiSummary]];
};

export const getJotaiDataToHydrate = (
	store: ReturnType<typeof createStore>,
) => {
	const reviews = store.get(reviewsAtom);
	const reviewAiSummary = store.get(reviewAiSummaryAtom);
	const pricing = getPricingDataToHydrate(store);
	const featureFlags = store.get(featureFlagsAtom);
	const detailedPricing = getDetailedPricingDataToHydrate(store);
	return {
		featureFlags,
		reviews,
		reviewAiSummary,
		pricing,
		detailedPricing,
	};
};

export const getStorefrontJotaiInitialValues = (
	initialJotaiState: InitialJotaiState,
) => {
	const { featureFlags, reviews, reviewAiSummary, pricing, detailedPricing } =
		initialJotaiState;
	return [
		...getReviewsInitialValues(reviews),
		...getReviewAiSummaryInitialValues(reviewAiSummary),
		...getPricingInitialValues(pricing),
		...getFeatureFlagsInitialValues(featureFlags),
		...getDetailedPricingInitialValues(detailedPricing),
	];
};

export const getJotaiInitialValues = (jotai: InitialJotaiState | undefined) => {
	if (!jotai) return [];
	return [...getStorefrontJotaiInitialValues(jotai)];
};

export const setFeatureFlags = (
	store: ReturnType<typeof createStore>,
	featureFlags: FeatureFlags,
) => {
	store.set(featureFlagsAtom, featureFlags);
};
