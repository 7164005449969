import { cloneDeep } from 'lodash';
import {
	categoryToRemove,
	getPagination,
	getSearchFallback,
	moveAppliedFiltersToSidebar,
	moveFilterToPillArea,
} from './helpers';
import initialState from './initialState';

export * from './actions';
export { default as intitalState } from './initialState';

export const DEFAULT_ALL_RADIO_OPTION_ID = 'DEFAULT_ALL_RADIO_OPTION_ID';
export const filtersNamesWithAllOption = [
	'Starting Price Range',
	'Distance',
	'Guest Capacity',
];

export const allOptions = {
	id: DEFAULT_ALL_RADIO_OPTION_ID,
	name: 'All',
	display: {
		patternsIconClass: '',
	},
	singular: {
		slug: 'All',
		term: 'All',
	},
	plural: {
		slug: 'All',
		term: 'All',
	},
};

const handleTransferFilters = (
	pillLimit: Search.FilterPillLimit,
	needToDropPill: boolean,
	stateCopy: Redux.Search,
) => {
	if (pillLimit < 6 && needToDropPill) {
		while (stateCopy.filterPills.categories.length > pillLimit - 1) {
			const [category, categoryIndex] = categoryToRemove(
				stateCopy.filterPills.categories,
			);
			stateCopy.filterPills.categories.splice(categoryIndex, 1);
			stateCopy.filterPills.sidebar.categories.unshift(category);
			const filter = stateCopy.filters.filters.find((f) => f.name === category);
			if (filter) {
				moveAppliedFiltersToSidebar(stateCopy, filter);
			}
		}
	} else if (pillLimit > stateCopy.filterPills.limit) {
		while (
			stateCopy.filterPills.categories.length < pillLimit - 1 &&
			stateCopy.filterPills.sidebar.categories.length
		) {
			moveFilterToPillArea(stateCopy);
		}
	}
};

interface Query {
	[key: string]: string;
}
const excludedKeys = new Set(['page', 'sort']);

const buildQueryValues = (query: Query = {}) => {
	return Object.entries(query)
		.filter(([key]) => !excludedKeys.has(key))
		.flatMap(([_, value]) => value.split(' '));
};

export default function searchReducer(
	state = initialState,
	action: Search.ActionTypes,
) {
	const stateCopy = cloneDeep(state);
	switch (action.type) {
		case 'FETCH_SEARCH_RESULTS':
			stateCopy.isFetching = true;
			stateCopy.total = stateCopy.filterPills.stagedResultsCount;
			break;
		case 'FETCH_SEARCH_RESULTS_SUCCESS': {
			const { data } = action.payload;
			stateCopy.boundingBox = data.search.boundingBox || null;
			stateCopy.isFetching = false;
			stateCopy.searchFallback = getSearchFallback(data.searchFallback);
			stateCopy.pagination = getPagination(data.search.pagination);
			stateCopy.vendors = data.search.profiles;
			stateCopy.seed = action.payload.seed;
			stateCopy.sort = data.search.sort.type;
			stateCopy.supplemental = data.search.supplemental;
			stateCopy.total = data.search.total.profiles;
			stateCopy.filterPills.stagedResultsCount = data.search.total.profiles;
			stateCopy.sortVariation = data.search.sort.variation;
			stateCopy.sortVersion = data.search.sort.version;
			stateCopy.scoreType = data.search.sort.scoreType;
			stateCopy.isPermitted = data.isPermitted ? data.isPermitted.flag : true; // not required in FE calls
			stateCopy.searchCopy = data.searchCopy.html;
			stateCopy.filterPills.deiFilters = data.search.deiFilters || [];
			stateCopy.marketCode = data.search.marketCode || '';

			break;
		}
		case 'FETCH_SEARCH_RESULTS_ERROR': {
			stateCopy.isFetching = false;
			stateCopy.vendors = [] as Vendor.Raw[];
			stateCopy.supplemental = [] as Vendor.Raw[];
			const errors = action.payload.errors || [];
			stateCopy.errorMessage = errors[0]?.message;
			stateCopy.filterPills.deiFilters = [];
			break;
		}
		case 'filters/UPDATE_PAGE':
			stateCopy.pagination = {
				...state.pagination,
				page: action.payload.page,
			};
			break;

		case 'filters/UPDATE_SORT':
			stateCopy.sort = action.payload.sort;
			break;

		case 'filters/SET_CATEGORY_FILTERS':
		case 'filters/POPULATE_CATEGORY_FILTERS': {
			const { filters, facetParam, query } = action.payload;
			const queryValues = buildQueryValues(query);
			const filterOptions = filters.filters.map((filter) =>
				filtersNamesWithAllOption.includes(filter.name)
					? { ...filter, filterOptions: [allOptions, ...filter.filterOptions] }
					: filter,
			);
			stateCopy.filters = { ...filters, filters: filterOptions };

			filters.filters.forEach((f) => {
				const filterForFacet = f.filterOptions.filter((o) => {
					// if the facet is outdoor, we want to include both covered and uncovered outdoor spaces
					if (f.name === 'Outdoor Space' && facetParam === 'outdoor') {
						return true;
					}
					// avoiding the duplicate Venue Amenities 'outdoor' options in the filter pills
					if (
						f.name === 'Wedding Venue Amenities' &&
						(o.name === 'Outdoor Event Space' ||
							o.name === 'Covered Outdoors Space')
					) {
						return false;
					}

					return (
						queryValues.includes(o.singular.slug) ||
						queryValues.includes(o.plural.slug) ||
						[o.singular.slug, o.plural.slug].includes(facetParam)
					);
				});

				const isSidebarFilter =
					stateCopy.filterPills.sidebar.categories.includes(f.name);

				filterForFacet.forEach((option) => {
					const appliedFilter = {
						id: option.id,
						name: option.name,
						value: facetParam || option.singular.slug,
						categorySlug: f.slug,
					};

					stateCopy.filterPills.applied.push(appliedFilter);

					if (isSidebarFilter) {
						stateCopy.filterPills.sidebar.applied.push(appliedFilter);
						stateCopy.filterPills.sidebar.selected.push(appliedFilter.id);
					}
				});
			});
			break;
		}
		case 'filters/UPDATE_FOLLOWABLE_FACETS':
			stateCopy.followableFacets = action.payload.followableFacets;
			break;

		case 'filters/RECEIVE_FILTER_PILLS':
			stateCopy.filterPills.categories = action.pills;
			stateCopy.filterPills.sidebar.categories = action.sidebar;
			stateCopy.filterPills.limit = action.limit;
			break;

		case 'filters/SET_AVAILABLE_SPACE': {
			stateCopy.sizes[action.item] = action.availableSpace;
			break;
		}

		case 'ADD_APPLIED_FILTER_PILL_OPTIONS':
			stateCopy.filterPills.staged.add = action.payload.options.filter(
				(option) => option.id !== DEFAULT_ALL_RADIO_OPTION_ID,
			);
			break;

		case 'REMOVE_APPLIED_FILTER_PILL_OPTION': {
			stateCopy.filterPills.staged.remove = action.payload.options;
			break;
		}

		case 'COMMIT_APPLIED_FILTER_PILL_CHANGES':
			stateCopy.filterPills.staged.commitChanges = true;
			break;

		case 'RESET_STAGED_FILTER_PILL_CHANGES': {
			stateCopy.filterPills.staged.commitChanges = false;
			stateCopy.filterPills.staged.remove.forEach((f) => {
				const indexToRemove = stateCopy.filterPills.applied.findIndex(
					(a) => a.id === f.id,
				);
				stateCopy.filterPills.applied.splice(indexToRemove, 1);
			});
			stateCopy.filterPills.applied = [
				...stateCopy.filterPills.staged.add,
				...stateCopy.filterPills.applied,
			];
			stateCopy.filterPills.staged.add = [];
			stateCopy.filterPills.staged.remove = [];
			break;
		}

		case 'CLEAR_APPLIED_FILTER_PILL_OPTIONS': {
			stateCopy.filterPills.staged.remove = [...stateCopy.filterPills.applied];
			stateCopy.filterPills.staged.commitChanges = true;
			break;
		}

		case 'SET_IS_SIDEBAR_OPEN': {
			stateCopy.filterPills.sidebar.isSidebarOpen = action.payload;
			break;
		}

		case 'MODIFY_SELECTED': {
			stateCopy.filterPills.sidebar.selected = action.payload.filter(
				(option) => option !== DEFAULT_ALL_RADIO_OPTION_ID,
			);
			break;
		}

		case 'SET_CLEAR_SIDEBAR_FLAG': {
			stateCopy.filterPills.sidebar.clearSidebarFlag = action.payload;
			break;
		}

		case 'CLEAR_SIDEBAR_FILTERS': {
			stateCopy.filterPills.sidebar.selected = [];
			break;
		}

		case 'MODIFY_APPLIED': {
			stateCopy.filterPills.sidebar.applied = action.payload;
			break;
		}

		case 'SET_FILTER_PILL_LIMIT': {
			const { payload } = action;
			if (payload !== stateCopy.filterPills.limit) {
				const moreFiltersButtonExists =
					stateCopy.filterPills.sidebar.categories.length > 0;
				const pillWithDropdownCount = stateCopy.filterPills.categories.length;
				const needToDropPill = moreFiltersButtonExists
					? pillWithDropdownCount > payload - 1
					: pillWithDropdownCount > payload;

				handleTransferFilters(payload, needToDropPill, stateCopy);
				stateCopy.filterPills.limit = action.payload;
			}
			break;
		}

		case 'SET_MAX_RESULT_COLUMNS': {
			stateCopy.maxResultColumns = action.payload;
			break;
		}

		case 'filters/SET_STAGED_RESULTS_COUNT': {
			stateCopy.filterPills.stagedResultsCount = action.payload;
			break;
		}

		case 'filters/RESET_STAGED_RESULTS_COUNT': {
			stateCopy.filterPills.stagedResultsCount = stateCopy.total;
			break;
		}

		default:
			return state;
	}
	return stateCopy;
}
