import honeybadger from '@honeybadger-io/js';

const position = (
	index: number,
	details: VendorImpressions.ImpressionDetails,
) => {
	honeybadger.setContext({ details, index });
	honeybadger.notify(
		new Error(
			`Impression Position is invalid, triggered for ${details.impressionType}`,
		),
	);
	honeybadger.resetContext();
	return index;
};

const report = {
	position,
};

export default report;
