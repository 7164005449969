/* eslint-disable camelcase */
import Honeybadger from '@honeybadger-io/js';
import { weddingsApiKey } from '@settings';
import { determineReferredMarketCode } from '@utils';
import { getPlatform } from '@utils/getPlatform';
import WeddingSDK from '@xo-union/sdk-weddings';
import type { InlineRfqSourceContent } from 'types/sourceContent';
import { VENDOR_REFERRED_MARKET_CODE } from '../../../constants/cookies';
import MembershipApi from '../../../lib/xo-rfq-forms/api/membership';
import WeddingsApi from '../../../lib/xo-rfq-forms/api/weddings';
import LocalStorage from '../../../lib/xo-rfq-forms/data/loader/local_storage';
import { localStorageCommonProps } from '../../../lib/xo-rfq-forms/data/loader/utils/general';
import { getVisitId, getVisitorId } from '../../../utils/cookies';
import { cookieGetItem } from '../../utils/cookie/consentManagementCookie';

interface BuildInquiryDataProps {
	anonymousId: string;
	fields: Rfq.InlineFields;
	flexibleWeddingDate: boolean;
	vendor: Vendor.Decorated | null;
	isFromSearch?: boolean;
	isReferred?: boolean;
	marketCode?: string;
	member: Membership.Member | null;
	initiator: string;
	overrideTextBody?: string;
}

const PW_REQUIREMENTS_LIST = [
	'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
	'abcdefghijklmnopqrstuvwxyz',
	'0123456789',
	'!@#$%^&*',
];

const pickRandomValue = (valuesToChooseFrom: string) => {
	const LIMIT = 4;
	return Array.from(crypto.getRandomValues(new Uint32Array(LIMIT)))
		.map((item) => valuesToChooseFrom[item % valuesToChooseFrom.length])
		.join('');
};

const shuffleString = (unshuffledString: string): string => {
	const shuffled = unshuffledString
		.split('')
		.sort(() => 0.5 - Math.random())
		.join('');

	if (shuffled !== unshuffledString) {
		return shuffled;
	}

	return shuffleString(shuffled);
};

const generateTemporaryPassword = () => {
	const unshuffledString = PW_REQUIREMENTS_LIST.map((requirement) =>
		pickRandomValue(requirement),
	).join('');
	return shuffleString(unshuffledString);
};

const signUpUser = async ({
	signUpDetails,
	membershipService,
}: AutoAccountCreation.SignUpUser) => {
	return membershipService?.membershipService.signUp({ ...signUpDetails });
};

const getTokenForUser = async (
	membershipService: MembershipService.Service,
) => {
	return membershipService.membershipService.getSessionToken();
};

const upsertWeddingForUser = async ({
	memberUuid,
	token,
	weddingDate,
}: AutoAccountCreation.UpsertWeddingForUser) => {
	const weddingClient = new WeddingSDK({
		apiKey: weddingsApiKey,
		membershipSessionToken: token,
	});
	return weddingClient.upsertWedding({
		wedding: {
			member_id: memberUuid,
			wedding_date: weddingDate,
		},
	});
};

const logInUser = ({
	logInDetails,
	membershipService,
}: AutoAccountCreation.LogInUser) => {
	return membershipService.membershipService.logIn({ ...logInDetails });
};

export const PasswordSessionStorageKeyName = 'tkww-aac-temp';

const fetchTemporaryPassword = () =>
	window?.sessionStorage?.getItem(PasswordSessionStorageKeyName);

const changeUserPassword = ({
	changePasswordDetails,
	membershipService,
}: AutoAccountCreation.ChangeUserPassword) => {
	return membershipService.membershipService.changePassword({
		...changePasswordDetails,
	});
};

const determineFieldValues = (state: Redux.State, type?: string) => {
	if (type === 'inline') {
		const { emailAddress, firstName, lastName, weddingDate } =
			state.rfq.inline.fields;
		return {
			emailAddress: emailAddress.value,
			firstName: firstName.value,
			lastName: lastName.value,
			weddingDate: weddingDate.value,
		};
	}
	const {
		emailAddress = '',
		firstName = '',
		lastName = '',
		xo_date_picker,
	} = state.rfq.rfqFormData;
	const weddingDate = xo_date_picker?.weddingDate || '';
	return { emailAddress, firstName, lastName, weddingDate };
};

const buildSenderInfo = (
	fields: Rfq.InlineFields,
	member: Membership.Member | null,
	flexibleWeddingDate: boolean,
) => {
	const {
		emailAddress,
		firstName,
		guestCount,
		lastName,
		phoneNumber,
		weddingDate,
	} = fields;
	const [min, max] = guestCount.value.split('-').map((count) => Number(count));
	const phoneAndContactInfo = phoneNumber.value
		? { phone: phoneNumber.value }
		: {};
	const senderInfo = {
		details: { flexibleWeddingDate },
		email: emailAddress.value,
		maxGuestCount: max,
		minGuestCount: min,
		name: { first: firstName.value, last: lastName.value },
		weddingDate: weddingDate.value,
		...phoneAndContactInfo,
	};

	return member
		? { senderInfo: { ...senderInfo, id: member.id } }
		: { senderAnonymousInfo: senderInfo };
};

const buildInquiryData = (props: BuildInquiryDataProps) => {
	const {
		anonymousId,
		fields,
		flexibleWeddingDate,
		isFromSearch,
		isReferred,
		marketCode,
		member,
		initiator,
		vendor,
		overrideTextBody,
	} = props;
	const {
		emailAddress,
		firstName,
		guestCount,
		lastName,
		phoneNumber,
		textarea,
		weddingDate,
	} = fields;
	const sendInfo = buildSenderInfo(fields, member, flexibleWeddingDate);
	const visitId = getVisitId();
	const visitorId = getVisitorId();
	const referredMarketCode = determineReferredMarketCode({
		cookieMarketCode: cookieGetItem(VENDOR_REFERRED_MARKET_CODE),
		isFromSearch,
		isReferred,
		marketCode,
	});
	const visitIds = visitId && visitorId ? { visitId, visitorId } : {};

	return {
		anonymousId,
		body: overrideTextBody || textarea.value,
		email: emailAddress.value,
		first_name: firstName.value,
		isRfq: true,
		isVRT: false,
		last_name: lastName.value,
		leadFormType: 'direct',
		number_of_guests: guestCount.value,
		...sendInfo,
		source: {
			application: 'theknot',
			categoryCode: vendor?.categoryCode,
			feature: 'beta',
			marketCode: vendor?.marketCode,
			platform: getPlatform(),
		},
		sourceContent: initiator,
		sourcePage: vendor?.isPaid ? 'paid storefront' : 'unpaid storefront',
		storefrontId: vendor?.id,
		tags: ['INBOX'],
		user_email: emailAddress.value,
		vendorReferredMarketCode: referredMarketCode,
		wedding_date: weddingDate.value,
		...visitIds,
	};
};

const updateLocalStorage = (
	formData: Rfq.FormData,
	code: Category.CategoryCode,
) => {
	const current = LocalStorage.get() || {};
	const commonProps: Partial<Rfq.LocalStorageCommonProps> = {};
	const data = JSON.parse(JSON.stringify(formData));

	localStorageCommonProps().forEach((key) => {
		if (key in data) {
			commonProps[key as Rfq.CommonPropKeys] = data[key];
			delete data[key];
		}
	});

	const categoryData: Partial<Rfq.LocalStorageCategoryData> = {};
	if (Object.keys(data).length) {
		categoryData[code] = data;
	}

	const output = Object.assign(current, categoryData, commonProps);
	LocalStorage.set(output);
};

const updateWeddings = (
	formData: Rfq.FormData,
	category: Category.CategoryCode,
	member: Redux.Membership['member'],
) => {
	// We do not want to update the member's details in the RFQ flow if they're logged in
	if (member) {
		return false;
	}

	return new WeddingsApi({ category, member, formData })
		.upsert()
		.catch((error) =>
			Honeybadger.notify(error, 'WeddingsApi Error - InlineRfq'),
		);
};

const updateMember = (
	inquiryData: Rfq.InquiryData,
	member: Redux.Membership['member'],
) => {
	// We do not want to update the member's details in the RFQ flow if they're logged in
	if (member) {
		return false;
	}

	return new MembershipApi(member, inquiryData)
		.update()
		.catch((error: string) =>
			Honeybadger.notify(error, 'MembershipApi Error - InlineRfq'),
		);
};

interface GenerateCookieData {
	(
		cookie: Rfq.Cookie,
		code: Category.CategoryCode,
	): {
		cookieData: Rfq.CookieData;
		flexibleWeddingDate: boolean | undefined;
	};
}

const generateCookieData: GenerateCookieData = (cookie, code) => {
	const {
		emailAddress,
		firstName,
		guest_count,
		lastName,
		[code]: categoryDetails,
		phoneNumber,
		xo_date_picker,
	} = cookie;
	const cookieData: Rfq.CookieData = {
		emailAddress,
		firstName,
		guestCount: guest_count?.display,
		lastName,
		phoneNumber,
		textarea: categoryDetails?.body,
		weddingDate: xo_date_picker?.weddingDate,
	};

	return {
		cookieData,
		flexibleWeddingDate: categoryDetails?.flexibleWeddingDate,
	};
};

const resolveSourceContent = (
	isMobile: boolean,
	isTablet: boolean,
	code: string,
): InlineRfqSourceContent => {
	if (code === 'BEA') {
		return 'Message Vendor CTA';
	}
	return !isMobile && !isTablet
		? 'inline RFQ pricing top'
		: 'inline RFQ CTA pricing';
};

export {
	buildInquiryData,
	changeUserPassword,
	determineFieldValues,
	fetchTemporaryPassword,
	generateCookieData,
	generateTemporaryPassword,
	getTokenForUser,
	logInUser,
	pickRandomValue,
	PW_REQUIREMENTS_LIST,
	resolveSourceContent,
	shuffleString,
	signUpUser,
	updateLocalStorage,
	upsertWeddingForUser,
	updateMember,
	updateWeddings,
};
