import { useInjectFavoritesNavLink } from '@hooks/use-inject-favorites-nav-link';
import HeaderNav from '@xo-union/tk-component-header-nav-redux/remote';
import '@xo-union/tk-ui-essentials';
import React, { useRef } from 'react';

interface Props {
	analyticsProps: { product: string };
	metadata: { applicationName: string };
	loggedIn: boolean;
}

export const HeaderNavWithInjectedLinks = ({
	analyticsProps,
	metadata,
	loggedIn,
}: Props) => {
	const headerNavRef = useRef<HTMLDivElement>(null);
	useInjectFavoritesNavLink(headerNavRef);

	return (
		<div ref={headerNavRef}>
			<HeaderNav
				ssi
				analyticsProps={analyticsProps}
				metadata={metadata}
				loggedIn={loggedIn}
				activeProduct="vendors"
			/>
		</div>
	);
};
